@font-face {
  font-family: Indie Flower;
  src: local('Indie Flower'),
    local('IndieFlower'),
    url('/fonts/indie_flower_regular.woff2') format('woff2'),
    url('/fonts/indie_flower_regular.woff') format('woff')
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: local('Montserrat Medium'),
    local('Montserrat-Medium'),
    url('/fonts/montserrat_500.woff2') format('woff2'),
    url('/fonts/montserrat_500.woff') format('woff')
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: local('Montserrat Bold'),
    local('Montserrat-Bold'),
    url('/fonts/montserrat_700.woff2') format('woff2'),
    url('/fonts/montserrat_700.woff') format('woff')
}

@font-face {
  font-family: Lora;
  src: local('Lora Regular'),
    local('Lora-Regular'),
    url('/fonts/lora_regular.woff2') format('woff2'),
    url('/fonts/lora_regular.woff') format('woff')
}

@font-face {
  font-family: Lora;
  font-style: italic;
  src: local('Lora Italic'),
    local('Lora-Italic'),
    url('/fonts/lora_italic.woff2') format('woff2'),
    url('/fonts/lora_italic.woff') format('woff')
}

@font-face {
  font-family: Lora;
  font-weight: 700;
  src: local('Lora Bold'),
    local('Lora-Bold'),
    url('/fonts/lora_700.woff2') format('woff2'),
    url('/fonts/lora_700.woff') format('woff')
}

$site-horizontal-padding: calc(3vw + 0.3em);

$heading-font-family: Montserrat, sans-serif;

$grape: #2d10b9;
$black: #15112b;
$light-gray: #d7d7dc;
$lighter-gray: #f0f0f3;

$link-color: $grape;

html {
  font-family: Lora, serif;
  font-size: 1.1em;
  font-size: unquote('min(max(1.1em, calc(0.9em + 0.7vw)), 1.2em)');
  line-height: 1.6;
  color: $black;
}

body {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heading-font-family;
}

a {
  color: $link-color;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.site-header {
  margin: 1em 0 2em;
  padding: 0 $site-horizontal-padding;

  @media (min-width: 32em) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.site-logo {
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    vertical-align: top;
    height: 3.3em;
    width: auto;
  }
}

.site-nav {
  display: flex;
  flex-wrap: wrap;
  font-family: $heading-font-family;
  font-weight: 500;
  margin: 1em -0.5em 0;

  a {
    color: inherit;
    text-decoration: none;
    margin: 0 0.5em;
    padding: 0.2em 0;

    &:hover {
      color: $link-color;
    }
  }

  @media (min-width: 32em) {
    margin-top: 0;
  }
}

.site-footer {
  margin: 2rem 0 0;
  padding-top: 1rem;
  background: $grape;
  clip-path: polygon(0% 1rem, 100% 0%, 100% 100%, 0% 100%);
  color: white;
  font-size: 0.9em;
  font-family: $heading-font-family;
  font-weight: 500;
  text-align: center;

  .content {
    padding: 1rem $site-horizontal-padding;
    max-width: 32em;
    margin: 0 auto;
  }

  a {
    color: inherit;
  }
}

.page-container {
  max-width: 32em;
  margin: 0 auto;
  padding: 0 $site-horizontal-padding;
}

.blog-header {
  margin: 2em 0 2em;
}

.page-title {
  font-weight: 800;
  font-size: 1.7em;
  line-height: 1.3;
  margin: 2rem 0 1rem;
}

.post-meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -0.3rem -0.4rem;
}

.post-date {
  font-size: 0.9em;
  font-style: italic;
  margin: 0.3rem 0.4rem;
}

.post-tags {
  display: flex;
  flex-wrap: wrap;
  font-family: $heading-font-family;
  font-size: 0.7em;
  font-weight: 500;
  margin: 0.1rem 0.2rem;

  a {
    margin: 0.2rem;
    padding: 0.2em 0.8em;
    background: $lighter-gray;
    border-radius: 9em;
    color: inherit;
    text-decoration: none;
  }
}

.formatted-content {
  h2 {
    font-size: 1.3em;
    line-height: 1.4;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  hr {
    border: none;
    height: 0.8em;
    width: 38%;
    min-width: 9em;
    margin: 2em auto;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='120' height='32' viewBox='0 0 120 32'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23f43' stroke-width='6' d='M-60 48l30-32a41 41 0 0160 0 41 41 0 0060 0 41 41 0 0160 0 41 41 0 0060 0 41 41 0 0160 0 41 41 0 0060 0 41 41 0 0160 0 41 41 0 0060 0 41 41 0 0160 0l30 32h0'/%3E%3C/svg%3E") top center / contain;
  }

  pre, :not(pre) > code {
    background-color: $lighter-gray;
    border-radius: 0.25em;
  }

  pre {
    overflow-x: auto;
    line-height: 1.4;
    padding: 0.6em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  :not(pre) > code {
    padding: 0.2em 0.4em;
    word-break: break-all;
  }

  table {
    border-collapse: collapse;
    font-size: 0.9em;
    margin: 1.5em auto;
  }

  thead {
    font-family: $heading-font-family;
  }

  th, td {
    padding: 0.3em 0.6em;
    border: 1px solid $light-gray;
  }

  li, li p {
    margin-top: 0.7em;
    margin-bottom: 0.7em;
  }
}

.home-container {
  display: grid;
  grid-gap: 2em 4%;
  padding: 0 $site-horizontal-padding;
  margin: 0 auto;
  max-width: 60em;

  .section-title {
    font-size: 1.5em;
    line-height: 1.3;
    font-weight: 800;
    margin: 1rem 0;
  }

  @media (min-width: 59em) {
    grid-template-columns: 528px 1fr;
    grid-template-rows: min-content 1fr;

    .bio-section {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
    }

    .blog-posts-section {
      grid-column: 2 / 3;
      grid-row: 1 / 3;
    }

    .elsewhere-section {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
    }
  }
}

.bio-section {
  $left-padding: 15%;
  $top-padding: calc(3 * var(--line-height));
  $background-color: #ffc;
  $horizontal-line-color: #cce0ff;
  $vertical-line-color: #fcc;
  $vertical-line-width: 1px;
  $vertical-line-spacing: 4px;
  $vertical-lines-total-width: $vertical-line-width * 2 + $vertical-line-spacing;

  font-family: Indie Flower, cursive;
  font-size: 20px;
  --line-height: 24px;
  line-height: var(--line-height);
  max-width: 24em;
  padding: 1em 0 0;

  .content {
    padding-top: $top-padding;
    background: linear-gradient($background-color, $background-color $top-padding, transparent $top-padding, transparent) top -1px center, $background-color repeating-linear-gradient(to top, $horizontal-line-color, $horizontal-line-color 1px, transparent 1px, transparent var(--line-height));
    box-shadow: 0 0.1em 0.2em rgba(#000, 0.1), 0.1em 0.4em 0.8em rgba(#000, 0.1);
    transform: rotate(-1deg);

    &::before { // vertical lines
      content: '';
      position: absolute;
      top: 0;
      left: calc(#{$left-padding} - #{$vertical-lines-total-width});
      height: 100%;
      width: $vertical-lines-total-width;
      background: repeating-linear-gradient(to right, $vertical-line-color, $vertical-line-color $vertical-line-width, transparent $vertical-line-width, transparent $vertical-line-width + $vertical-line-spacing, $vertical-line-color $vertical-line-width + $vertical-line-spacing, $vertical-line-color $vertical-lines-total-width);
    }
  }

  p {
    margin: 0;
    padding: 0 1.5em var(--line-height) calc(#{$left-padding} + 1em);

    // adjust baseline to horizontal lines
    &:first-of-type {
      padding-top: calc(var(--line-height) * 0.125);
    }

    &:last-child {
      padding-bottom: calc(var(--line-height) * 0.875);
    }
  }

  .polaroid {
    background: white;
    margin: calc(0px - #{$top-padding} - 0.25em) 0 1em 1em;
    padding: 4%;
    width: 75%;
    transform: rotate(5deg);
    box-shadow: 0 0.1em 0.2em rgba(#000, 0.05), 0.1em 0.4em 0.4em rgba(#000, 0.05);

    img {
      width: 100%;
    }

    figcaption {
      margin-left: 8%;
      padding: 3% 0;
      font-size: 0.8em;
      font-weight: bold;
      line-height: 1;
      transform: rotate(-2deg);
    }
  }

  .paperclip {
    $width: 1em;
    $height: 3em;
    $thickness: 0.1em;
    $border: $thickness solid #bbb;

    position: absolute;
    top: -1em;
    left: 3em;
    width: $width / 2;
    height: $height / 3;
    border-top: $border;
    border-right: $border;
    border-top-right-radius: $width;

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &::before {
      top: -$thickness;
      right: $width / 2;
      width: $width / 2;
      height: $height / 3;
      border-top: $border;
      border-left: $border;
      border-top-left-radius: $width;
      z-index: -1;
    }

    &::after {
      top: 100%;
      right: -$thickness;
      width: calc(#{$width} - #{2 * $thickness});
      height: $height * 2 / 3;
      border: $border;
      border-top: none;
      border-radius: 0 0 $width $width;
    }
  }

  @media (min-width: 22em) {
    .polaroid {
      float: left;
      padding: 2%;
      width: 37.5%;
      margin-right: 1em;
    }
  }

  @media (min-width: 32em) {
    font-size: 22px;
    --line-height: 28px;
  }
}

.blog-posts-section .archive-link {
  font-family: $heading-font-family;
  font-size: 0.9em;
  font-weight: 500;
}

.post-list {
  margin: 1em 0;

  .post {
    padding: 1.3em 0;
    border-top: 1px solid $light-gray;

    &:last-of-type {
      border-bottom: 1px solid $light-gray;
    }
  }

  .post-title {
    font-size: 1.1em;
    line-height: 1.4;
    margin: 0 0 0.5rem;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: $link-color;
      }
    }
  }

  .formatted-content {
    margin-top: 0.5rem;
  }
}

.elsewhere-section {
  .link-list {
    list-style: none;
    padding: 0;
    font-family: $heading-font-family;
    font-size: 0.9em;
    font-weight: 500;
  }

  li {
    margin: 0.5em 0;
  }

  a {
    vertical-align: top;
    display: inline-flex;
    align-items: center;
  }

  svg {
    height: 2em;
    width: auto;
    margin-right: 0.25em;
    flex-shrink: 0;
  }
}

@import 'highlight';
