.highlight .hll {
  background-color: #ffc;
}

.highlight .c {
  color: #408080;
  font-style: italic;
}

.highlight .err {
  border: 1px solid #f00;
}

.highlight .k {
  color: #008000;
  font-weight: bold;
}

.highlight .o {
  color: #666;
}

.highlight .cm {
  color: #408080;
  font-style: italic;
}

.highlight .cp {
  color: #bc7a00;
}

.highlight .c1 {
  color: #408080;
  font-style: italic;
}

.highlight .cs {
  color: #408080;
  font-style: italic;
}

.highlight .gd {
  color: #a00000;
}

.highlight .ge {
  font-style: italic;
}

.highlight .gr {
  color: #f00;
}

.highlight .gh {
  color: #000080;
  font-weight: bold;
}

.highlight .gi {
  color: #00a000;
}

.highlight .go {
  color: #808080;
}

.highlight .gp {
  color: #000080;
  font-weight: bold;
}

.highlight .gs {
  font-weight: bold;
}

.highlight .gu {
  color: #800080;
  font-weight: bold;
}

.highlight .gt {
  color: #0040d0;
}

.highlight .kc {
  color: #008000;
  font-weight: bold;
}

.highlight .kd {
  color: #008000;
  font-weight: bold;
}

.highlight .kn {
  color: #008000;
  font-weight: bold;
}

.highlight .kp {
  color: #008000;
}

.highlight .kr {
  color: #008000;
  font-weight: bold;
}

.highlight .kt {
  color: #b00040;
}

.highlight .m {
  color: #666;
}

.highlight .s {
  color: #ba2121;
}

.highlight .na {
  color: #7d9029;
}

.highlight .nb {
  color: #008000;
}

.highlight .nc {
  color: #00f;
  font-weight: bold;
}

.highlight .no {
  color: #800;
}

.highlight .nd {
  color: #a2f;
}

.highlight .ni {
  color: #999;
  font-weight: bold;
}

.highlight .ne {
  color: #d2413a;
  font-weight: bold;
}

.highlight .nf {
  color: #00f;
}

.highlight .nl {
  color: #a0a000;
}

.highlight .nn {
  color: #00f;
  font-weight: bold;
}

.highlight .nt {
  color: #008000;
  font-weight: bold;
}

.highlight .nv {
  color: #19177c;
}

.highlight .ow {
  color: #a2f;
  font-weight: bold;
}

.highlight .w {
  color: #bbb;
}

.highlight .mf {
  color: #666;
}

.highlight .mh {
  color: #666;
}

.highlight .mi {
  color: #666;
}

.highlight .mo {
  color: #666;
}

.highlight .sb {
  color: #ba2121;
}

.highlight .sc {
  color: #ba2121;
}

.highlight .sd {
  color: #ba2121;
  font-style: italic;
}

.highlight .s2 {
  color: #ba2121;
}

.highlight .se {
  color: #b62;
  font-weight: bold;
}

.highlight .sh {
  color: #ba2121;
}

.highlight .si {
  color: #b68;
  font-weight: bold;
}

.highlight .sx {
  color: #008000;
}

.highlight .sr {
  color: #b68;
}

.highlight .s1 {
  color: #ba2121;
}

.highlight .ss {
  color: #19177c;
}

.highlight .bp {
  color: #008000;
}

.highlight .vc {
  color: #19177c;
}

.highlight .vg {
  color: #19177c;
}

.highlight .vi {
  color: #19177c;
}

.highlight .il {
  color: #666;
}
